import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'; // Import social media icons
import { faPhoneAlt, faEnvelope, faFileAlt } from '@fortawesome/free-solid-svg-icons'; // Import icons for contact and document
import companyLogo from '../assets/images/gwtf-final stripped black.png'; // Ensure the path to your logo is correct
import '../assets/css/Footer.css'; // Ensure you have corresponding CSS for styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__left">
        <div className="footer__contact-item">
          <FontAwesomeIcon icon={faPhoneAlt} className="footer__contact-icon" />
          <p>PHONE: 260-837-5555</p>
        </div>
        <div className="footer__contact-item">
          <FontAwesomeIcon icon={faEnvelope} className="footer__contact-icon" />
          <p>EMAIL: GOWITHTHEFLO365@GMAIL.COM</p>
        </div>
        <div className="footer__license">
          <FontAwesomeIcon icon={faFileAlt} className="footer__contact-icon" />
          <p>LICENSE: PC#12400117</p>
        </div>
      </div>
      <div className="footer__center">
        <img src={companyLogo} alt="Company Logo" className="footer__logo" />
      </div>
      <div className="footer__right">
        <div className="footer__social-icons">
          <a
            href="https://www.facebook.com/profile.php?id=61553632415928"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__social-link facebook"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a
            href="https://www.twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__social-link twitter"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__social-link instagram"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
