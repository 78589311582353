import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoadingScreen from './components/LoadingScreen';
import LandingPage from './components/LandingPage';
import Plumbing from './components/Plumbing';
import HomeMaintenance from './components/HomeMaintenance';
import BookingPage from './components/BookingPage';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Team from './components/Team';
import Gallery from './components/Gallery';
import './App.css';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2500); // Simulate loading time

    return () => clearTimeout(timer); // Clean up the timer
  }, []);

  return (
    <Router>
      <div className="App">
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <>
            <Navbar />
            <div className="content">
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/plumbing" element={<Plumbing />} />
                <Route path="/home-maintenance" element={<HomeMaintenance />} />
                <Route path="/booking" element={<BookingPage />} />
                <Route path="/team" element={<Team />} />
                <Route path="/gallery" element={<Gallery />} />
                {/* Catch-all route for undefined paths */}
                <Route path="*" element={<LandingPage />} />
              </Routes>
            </div>
            <Footer />
          </>
        )}
      </div>
    </Router>
  );
};

export default App;
