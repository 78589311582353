import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import animationData from '../assets/animations/gwf-load.json';
import '../assets/css/LoadingScreen.css';
import logo from '../assets/images/gwtf-final stripped black.png'; // Adjust the path based on your file structure
import dropletSound from '../assets/audio/dropletSound.mp3'; // Import the water droplet sound

const LoadingScreen = () => {
  const [showLogo, setShowLogo] = useState(false);

  useEffect(() => {
    const soundEffect = new Audio(dropletSound); // Initialize the audio object

    const timer = setTimeout(() => {
      // Play the sound when the splash animation occurs
      soundEffect.play().catch((err) => {
        console.error("Error playing sound:", err);
      });

      // Show the logo after the splash
      window.requestAnimationFrame(() => {
        setShowLogo(true);
      });
    }, 1900); // Synchronize with the splash animation timing (1900ms)

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="loading-screen">
      <div className="lottie-animation">
        <Lottie animationData={animationData} />
      </div>
      {showLogo && (
        <div className="logo-container">
          <img src={logo} alt="Company Logo" className="logo" />
        </div>
      )}
    </div>
  );
};

export default LoadingScreen;
