import React, { useEffect } from 'react';
import styles from '../assets/css/Booking.module.css'; // Importing CSS styles

const Booking = () => {
  useEffect(() => {
    // Load the Housecall Pro script dynamically if not already loaded
    if (!document.querySelector('script[src="https://online-booking.housecallpro.com/script.js"]')) {
      const script = document.createElement('script');
      script.src =
        'https://online-booking.housecallpro.com/script.js?token=90896e7369244b74931f57885b7df80e&orgName=Go-with-the-Flo-Plumbing';
      script.async = true;

      script.onload = () => {
        console.log('Housecall Pro script loaded.');
      };

      script.onerror = () => {
        console.error('Failed to load Housecall Pro script.');
      };

      document.body.appendChild(script);
    } else {
      console.log('Housecall Pro script already loaded.');
    }
  }, []);

  return (
    <main className={styles.bookingPage}>
      {/* Booking Button Section */}
      <section className={styles.bookingSection}>
        <h2>Ready to Book?</h2>
        <p>Click the button below to schedule your appointment online today!</p>

        {/* Housecall Pro Button */}
        <div className={styles.bookingButtonContainer}>
          <button
            data-token="90896e7369244b74931f57885b7df80e"
            data-orgname="Go-with-the-Flo-Plumbing"
            className={styles.hcpButton}
            onClick={() => {
              if (window.HCPWidget) {
                window.HCPWidget.openModal();
              } else {
                console.error('HCPWidget is not available.');
              }
            }}
          >
            Book Online Now
          </button>
        </div>
      </section>

      {/* Hero Section */}
      <header className={styles.heroSection}>
        <div className={styles.heroContent}>
          <h1 className={styles.heroTitle}>Book a Service with Ease</h1>
          <p className={styles.heroSubtitle}>
            Professional, fast, and reliable plumbing services at your fingertips.
          </p>
        </div>
      </header>

      {/* Booking Info Section */}
      <section className={styles.infoSection}>
        <h2>Convenient Online Booking</h2>
        <p>
          Take the hassle out of scheduling your plumbing services. Use our secure online booking system to choose the
          best time for your appointment. Our team is ready to help you with a wide range of plumbing services — from
          repairs to installations.
        </p>
        <ul className={styles.benefitsList}>
          <li>✔️ Fast, easy, and secure online booking</li>
          <li>✔️ Book at a time that suits your schedule</li>
          <li>✔️ Instant confirmation of your appointment</li>
          <li>✔️ Trusted and certified professionals</li>
        </ul>
      </section>
    </main>
  );
};

export default Booking;
