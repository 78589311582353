import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; // Import slick slider CSS
import "slick-carousel/slick/slick-theme.css"; // Import slick slider theme
import styles from '../assets/css/Plumbing.module.css';

// Importing carousel images
import plumbingImage1 from '../assets/images/plumbing/plumbingImage1.jpg';
import plumbingImage2 from '../assets/images/plumbing/plumbingImage2.jpg';
import plumbingImage3 from '../assets/images/plumbing/plumbingImage3.jpg';
import plumbingImage4 from '../assets/images/plumbing/plumbingImage4.jpg';
import plumbingImage5 from '../assets/images/plumbing/plumbingImage5.jpg';
import plumbingImage6 from '../assets/images/plumbing/plumbingImage6.jpg';
import plumbingImage7 from '../assets/images/plumbing/plumbingImage7.jpg';
import plumbingImage8 from '../assets/images/plumbing/plumbingImage8.jpg';
import plumbingImage9 from '../assets/images/plumbing/plumbingImage9.jpg';
import plumbingBackground from '../assets/images/plumbing/plumbingBackground.jpg';

const Plumbing = () => {
  // Settings for the slick slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true, // Enable fade effect for smooth transitions
  };

  // Array of images for the carousel
  const plumbingImages = [
    { src: plumbingBackground, alt: 'Background image' },
    { src: plumbingImage1, alt: 'Plumbing image 1' },
    { src: plumbingImage2, alt: 'Plumbing image 2' },
    { src: plumbingImage3, alt: 'Plumbing image 3' },
    { src: plumbingImage4, alt: 'Plumbing image 4' },
    { src: plumbingImage5, alt: 'Plumbing image 5' },
    { src: plumbingImage6, alt: 'Plumbing image 6' },
    { src: plumbingImage7, alt: 'Plumbing image 7' },
    { src: plumbingImage8, alt: 'Plumbing image 8' },
    { src: plumbingImage9, alt: 'Plumbing image 9' },
  ];

  const plumbingServices = {
    'Bathroom Plumbing': [
      { name: 'Shower Repair & Installation', description: 'Fix and replace showers, faucets, and pipes.' },
      { name: 'Toilet Plumbing', description: 'Repair and install toilets, addressing any leaks or malfunctions.' },
      { name: 'Video Pipe Inspection', description: 'Identify problems in your plumbing system with advanced technology.' }
    ],
    'Kitchen and Gas': [
      { name: 'Garbage Disposal Installation', description: 'Install and repair garbage disposals.' },
      { name: 'Kitchen Sink Repairs', description: 'Fix leaks and replace worn-out pipes or fixtures.' },
      { name: 'Gas Line Installation', description: 'Install new gas lines for stoves and other appliances.' }
    ],
    'Additional Services': [
      { name: 'Re-piping', description: 'Whole-house re-piping services for older homes.' },
      { name: 'Water Heaters', description: 'Install and maintain gas and electric water heaters.' }
    ]
  };

  return (
    <main className={styles.plumbingPage}>
      {/* Carousel Section */}
      <div className={styles.carouselContainer}>
        <Slider {...settings}>
          {plumbingImages.map((image, index) => (
            <div key={index} className={styles.carouselImage}>
              <img src={image.src} alt={image.alt} style={{ width: "100%", height: "400px", objectFit: "cover" }} />
              <div className={styles.carouselContent}>
                <h1 className={styles.heroTitle}>Expert Plumbing Services</h1>
                <p className={styles.heroSubtitle}>Professional, reliable, and fast solutions for all your plumbing needs.</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {/* Services Section */}
      <section className={styles.servicesSection}>
        {Object.entries(plumbingServices).map(([category, services], index) => (
          <div 
            className={`${styles.serviceCategory} ${category === 'Additional Services' ? styles.additionalServices : ''}`} 
            key={index}
          >
            <h3>{category}</h3>
            <div className={styles.serviceCards}>
              {services.map((service, serviceIndex) => (
                <div key={serviceIndex} className={styles.serviceCard}>
                  <strong>{service.name}</strong>
                  <p>{service.description}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </section>

      {/* Contact Section */}
      <section className={styles.contactSection}>
        <h2>Contact Us</h2>
        <p>For more information or to schedule a service, please contact us at:</p>
        <p><strong>Phone:</strong> (260) 837-5555 </p>
        <p><strong>Email:</strong> gowiththeflo365@gmail.com</p>
        <Link to="/booking" className={styles.bookingLink}>Book a Service</Link> {/* Updated to Link */}
      </section>
    </main>
  );
};

export default Plumbing;
