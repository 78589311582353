import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; // Import slick slider CSS
import "slick-carousel/slick/slick-theme.css"; // Import slick slider theme
import styles from '../assets/css/HomeMaintenance.module.css';

// Importing carousel images
import homeMaint1 from '../assets/images/home/homeMaint1.jpg';
import homeMaint2 from '../assets/images/home/homeMaint2.jpg';
import homeMaint3 from '../assets/images/home/homeMaint3.jpg';
import homeMaint4 from '../assets/images/home/homeMaint4.jpg';
import homeMaint5 from '../assets/images/home/homeMaint5.jpg';
import homeMaint6 from '../assets/images/home/homeMaint6.jpg';
import homeMaint7 from '../assets/images/home/homeMaint7.jpg';
import homeMaint8 from '../assets/images/home/homeMaint8.jpg';

const HomeMaintenance = () => {
  // Settings for the slick slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true, // Enable fade effect for smooth transitions
  };

  // Array of images for the carousel
  const maintenanceImages = [
    { src: homeMaint1, alt: 'Home Maintenance image 1' },
    { src: homeMaint2, alt: 'Home Maintenance image 2' },
    { src: homeMaint3, alt: 'Home Maintenance image 3' },
    { src: homeMaint4, alt: 'Home Maintenance image 4' },
    { src: homeMaint5, alt: 'Home Maintenance image 5' },
    { src: homeMaint6, alt: 'Home Maintenance image 6' },
    { src: homeMaint7, alt: 'Home Maintenance image 7' },
    { src: homeMaint8, alt: 'Home Maintenance image 8' },
  ];

  const homeMaintenanceServices = {
    'Installation Services': [
      { name: 'Light Fixture Installation', description: 'Expert installation of light fixtures to brighten up your home.' },
      { name: 'Door Hardware Installation', description: 'Professional door hardware installation for security and style.' },
      { name: 'Picture Hanging and Installations', description: 'Perfectly aligned picture hanging and installations for any room.' },
      { name: 'Window Coverings', description: 'Installation of blinds, shades, and curtains to enhance privacy and decor.' },
      { name: 'Screen Installation', description: 'Quality screen installation for windows and doors to keep pests out.' },
      { name: 'Appliance Installation', description: 'Efficient installation of home appliances with attention to detail.' },
      { name: 'Holiday Light Installation', description: 'Seasonal holiday light installation to bring festive cheer to your home.' },
      { name: 'TV Mount Installation', description: 'Secure TV mount installation for optimal viewing experience.' }
    ],
    'Repair Services': [
      { name: 'Screen Repairs', description: 'Fast and reliable screen repair services to fix any damage.' },
      { name: 'Fence Repairs', description: 'Expert fence repair services to maintain the security and aesthetics of your property.' },
      { name: 'Gate Repairs', description: 'Professional gate repair services to ensure smooth operation and safety.' }
    ]
  };

  return (
    <main className={styles.homeMaintenancePage}>
      {/* Carousel Section */}
      <div className={styles.carouselContainer}>
        <Slider {...settings}>
          {maintenanceImages.map((image, index) => (
            <div key={index} className={styles.carouselImage}>
              <img src={image.src} alt={image.alt} style={{ width: "100%", height: "400px", objectFit: "cover" }} />
              <div className={styles.carouselContent}>
                <h1 className={styles.heroTitle}>Home Maintenance Services</h1>
                <p className={styles.heroSubtitle}>Quality and reliable services to keep your home in top condition.</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {/* Services Section */}
      <section className={styles.servicesSection}>
        {Object.entries(homeMaintenanceServices).map(([category, services], index) => (
          <div key={index} className={styles.serviceCategory}>
            <h3>{category}</h3>
            <div className={styles.serviceCards}>
              {/* Render regular services */}
              {category === 'Installation Services'
                ? services.slice(0, services.length - 2).map((service, i) => (
                    <div key={i} className={styles.serviceCard}>
                      <strong>{service.name}</strong>
                      <p>{service.description}</p>
                    </div>
                  ))
                : services.map((service, i) => (
                    <div key={i} className={styles.serviceCard}>
                      <strong>{service.name}</strong>
                      <p>{service.description}</p>
                    </div>
                  ))}
            </div>
            {/* Render centered services */}
            {category === 'Installation Services' && (
              <div className={styles.centeredCards}>
                {services.slice(-2).map((service, i) => (
                  <div key={i} className={`${styles.serviceCard} ${styles.centeredCard}`}>
                    <strong>{service.name}</strong>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </section>

      {/* Contact Section */}
      <section className={styles.contactSection}>
        <h2>Contact Us</h2>
        <p>For more information or to schedule a service, please contact us at:</p>
        <p><strong>Phone:</strong> (260) 837-5555 </p>
        <p><strong>Email:</strong> gowiththeflo365@gmail.com</p>
        <Link to="/booking" className={styles.bookingLink}>Book a Service</Link> {/* Updated to Link */}
      </section>
    </main>
  );
};

export default HomeMaintenance;
