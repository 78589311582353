import React from 'react';
import '../assets/css/Team.css'; // Ensure this path matches your project structure
import teamMember1 from '../assets/images/team/ryanPic.jpg'; // Replace with actual team images
import teamMember2 from '../assets/images/team/faleshaPic.jpg';
import teamMember3 from '../assets/images/team/zachPic.jpg';

const Team = () => {
  const teamMembers = [
    {
      name: 'Ryan',
      position: 'Owner',
      description: 'As an owner of Go with the Flo Plumbing, husband of Falesha, and father of two, there is never a dull moment. After 13 years as a salesman and running his own business under Sweetwater he became an effective communicator and learned how to provide superior customer service. After all those years he had enough of the cubicle life so he decided to start Go with the Flo Plumbing. He has many years of experience in house flipping, home maintenance and home repairs. He loves creating music, spending time in nature, and fixing things.',
      image: teamMember1,
    },
    {
      name: 'Falesha',
      position: 'Owner/ Receptionist',
      description: "As a stay at home mother and co-owner of Go with the Flo Plumbing, she is always on the move managing the office and raising two children. She loves working out at the gym, spending time with her family, and soaking up the sun. Don't mention 'Winter' to her...",
      image: teamMember2,
    },
    {
      name: 'Zach',
      position: 'Licensed Plumber',
      description: "As a licenced plumber, family man, and father of three, he loves to put his plumbing experience to the test and always provides superior workmanship. He is always eager to learn and sharpen his skills. He loves to spend time with family, hangout at the lake, and make music on guitar. ",
      image: teamMember3,
    },
  ];

  return (
    <section className="team-section">
      <h2 className="team-title">Meet the Crew</h2>
      <div className="team-members">
        {teamMembers.map((member, index) => (
          <div className="team-member" key={index}>
            <div className="member-image-wrapper">
              <img src={member.image} alt={member.name} className="member-image" />
            </div>
            <h3 className="member-name">{member.name}</h3>
            <p className="member-position">{member.position}</p>
            <p className="member-description">{member.description}</p> {/* Added description */}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Team;
