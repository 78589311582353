import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/LandingPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench, faHome, faCalendarAlt, faToilet, faCogs } from '@fortawesome/free-solid-svg-icons';

// Import Ad video
import gwtfVideo from '../assets/video/gwtfVideo.mp4';

// Import Owner Picture
import logo from '../assets/images/logo.png';

// Import the new map image
import ftWayneImage from '../assets/images/ftWaynepic.png';

const LandingPage = () => {
  // Load Elfsight script for Customer Testimonials
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.setAttribute('data-use-service-core', '');
    script.defer = true;
    document.body.appendChild(script);
  }, []);

  return (
    <main className="landing-page">
      {/* Video and Services Section */}
      <section className="video-and-services-container">
        <div className="video-wrapper">
          {/* Embedded YouTube Video or Self-Hosted Video */}
          <iframe
            width="100%"
            height="450"
            src={gwtfVideo}
            alt='Go with the Flo ad'
            title="Go with the Flo Video Ad"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        {/* Services Section (Horizontally Aligned) */}
        <section className="services-section-horizontal">
          <div className="service-card">
            <Link to="/plumbing" className="service-link">
              <div className="service-content">
                <FontAwesomeIcon icon={faWrench} className="service-icon" />
                <h3>Plumbing Services</h3>
                <p>Expert plumbing services to keep your home running.</p>
                <Link to="/booking" className="book-now-button">
                  <FontAwesomeIcon icon={faCalendarAlt} /> Book Now
                </Link>
              </div>
            </Link>
          </div>
          <div className="service-card">
            <Link to="/home-maintenance" className="service-link">
              <div className="service-content">
                <FontAwesomeIcon icon={faHome} className="service-icon" />
                <h3>Home Repair Services</h3>
                <p>Professional home repair services to maintain your house.</p>
                <Link to="/booking" className="book-now-button">
                  <FontAwesomeIcon icon={faCalendarAlt} /> Book Now
                </Link>
              </div>
            </Link>
          </div>
          <div className="service-card">
            <Link to="/plumbing" className="service-link">
              <div className="service-content">
                <FontAwesomeIcon icon={faToilet} className="service-icon" />
                <h3>Sewer Services</h3>
                <p>Reliable sewage services to ensure a clean and safe home.</p>
                <Link to="/booking" className="book-now-button">
                  <FontAwesomeIcon icon={faCalendarAlt} /> Book Now
                </Link>
              </div>
            </Link>
          </div>
          <div className="service-card">
            <Link to="/home-maintenance" className="service-link">
              <div className="service-content">
                <FontAwesomeIcon icon={faCogs} className="service-icon" />
                <h3>Appliance Installations</h3>
                <p>Expert appliance installation services for your home.</p>
                <Link to="/booking" className="book-now-button">
                  <FontAwesomeIcon icon={faCalendarAlt} /> Book Now
                </Link>
              </div>
            </Link>
          </div>
        </section>
      </section>

      {/* About Us Section */}
      <section className="about-us-container">
        <div className="about-us-header">About Us</div>
        <div className="about-us-content">
          <div className="about-us-image">
            <img src={logo} alt="Portrait of Owner, Ryan" />
          </div>
          <div className="about-us-message">
            <h2>Our Mission:</h2>
            <p>Our vision is to be the plumber you always think to call! Whenever needed, we want you to know that you can count on us to assist with your plumbing needs.
               We provide an honest, down-to-earth experience while providing quality workmanship, and fair pricing.
               We pride ourselves on great communication so you’re never left in the dark.<strong>We are committed to 100% customer satisfaction!</strong>
            </p>
          </div>
        </div>
      </section>

      {/* Services and Map Layout */}
      <section className="top-section">
        <div className="map-container">
          {/* Replacing Google Map with the uploaded image */}
          <img src={ftWayneImage} alt="Fort Wayne Service Area" className="google-map" />

          {/* Text overlay on the map */}
          <div className="text-overlay">
            <h2>Service Area</h2>
            <p>Expert Plumbing & Home Maintenance Services</p>
            <p>Serving Fort Wayne and Surrounding Areas</p>
          </div>
        </div>
      </section>

      {/* Text Boxes Section Under Map */}
      <section className="text-boxes">

{/* Bottom-Right Overlay Content */}
<div className="bottom-right-overlay">
          <p>
            <strong>Proudly Serving:</strong><br />
            Angola - Arcola - Ashley - Auburn - Butler - Churubusco - Columbia City - Corunna - Fort Wayne - Garrett -
            Grabill - Hamilton - Huntertown - Kendallville - Laotto - Leo-Cedarville - New Haven - Saint Joe - Spencerville
            - Waterloo
          </p>
        </div>

        <div className="side-text">
          <h3>Expert Services</h3>
          <p>Providing top-notch plumbing and home repair solutions.</p>
        </div>

        <div className="side-text">
          <h3>Contact Us Today</h3>
          <p>We're here to help with all your home improvement needs.</p>
        </div>

        
      </section>

      {/* Google Reviews Section with Elfsight Embed */}
      <section className="google-reviews-section">
        <h2>Customer Testimonials</h2>
        <div className="elfsight-app-e9682f0e-1635-47df-a6f3-689729e211a4" data-elfsight-app-lazy></div>
      </section>
    </main>
  );
};

export default LandingPage;
