import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Suppress the ResizeObserver loop warning
const resizeObserverErrFix = () => {
  let resizeObserverErr = false;
  window.addEventListener("error", (e) => {
    if (e.message === "ResizeObserver loop completed with undelivered notifications.") {
      resizeObserverErr = true;
      e.stopImmediatePropagation();
    }
  });
  window.addEventListener("resize", () => {
    if (resizeObserverErr) {
      resizeObserverErr = false;
    }
  });
};

// Run the suppressor function
resizeObserverErrFix();

// Get the root DOM element
const rootElement = document.getElementById('root');

// Create a root and render the app
const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
