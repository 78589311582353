import React from 'react';
import Slider from 'react-slick'; // Import the react-slick slider
import "slick-carousel/slick/slick.css"; // Import slick slider CSS
import "slick-carousel/slick/slick-theme.css"; // Import slick slider theme
import '../assets/css/Gallery.css'; // Ensure the CSS path is correct

// Importing work images
import workImage1 from '../assets/images/carousel/backHoe.jpg';
import workImage2 from '../assets/images/carousel/bathroomRemod.jpg';
import workImage3 from '../assets/images/carousel/spigot.jpg';
import workImage4 from '../assets/images/carousel/tanklessHeater.jpg';
import workImage5 from '../assets/images/carousel/waterFilter.jpg';
import workImage6 from '../assets/images/carousel/mainDig.jpg';
import workImage7 from '../assets/images/carousel/yardPipe.jpg';
import workImage8 from '../assets/images/carousel/septicTank.jpg';
import workImage9 from '../assets/images/carousel/sinkDrain.jpg';
import workImage10 from '../assets/images/carousel/waxRing.jpg';
import workImage11 from '../assets/images/carousel/kitchRemod.jpg';
import workImage12 from '../assets/images/carousel/bathVac.jpg';
import workImage13 from '../assets/images/carousel/workImage13.jpg';
import workImage14 from '../assets/images/carousel/workImage14.jpg';
import workImage15 from '../assets/images/carousel/workImage15.jpg';
import workImage16 from '../assets/images/carousel/workImage16.jpg';
import workImage17 from '../assets/images/carousel/workImage17.jpg';
import workImage18 from '../assets/images/carousel/workImage18.jpg';
import workImage19 from '../assets/images/carousel/workImage19.jpg';
import workImage20 from '../assets/images/carousel/workImage20.jpg';
import workImage21 from '../assets/images/carousel/workImage21.jpg';
import workImage22 from '../assets/images/carousel/workImage22.jpg';
import workImage23 from '../assets/images/carousel/workImage23.jpg';
import workImage24 from '../assets/images/carousel/workImage24.jpg';

const Gallery = () => {
  // Settings for the react-slick slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true, // Show arrows on larger screens
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true, // Keep arrows for tablets
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false, // Hide arrows on mobile
          swipe: true, // Enable swipe gestures
        },
      },
    ],
  };

  // Array of work images
  const workImages = [
    { src: workImage1, alt: 'Backhoe filling in ditch' },
    { src: workImage2, alt: 'Bathroom remodel framing' },
    { src: workImage3, alt: 'Home spigot' },
    { src: workImage4, alt: 'Tankless water heater setup' },
    { src: workImage5, alt: 'Water Filter setup' },
    { src: workImage6, alt: 'Main Pipe trench dig-out' },
    { src: workImage7, alt: 'Main Plumbing in yard' },
    { src: workImage8, alt: 'Septic Tank' },
    { src: workImage9, alt: 'Sink Drain Plumbing' },
    { src: workImage10, alt: 'Wax Ring and Toilet mount' },
    { src: workImage11, alt: 'Kitchen Remodel in progress' },
    { src: workImage12, alt: 'Cleaning up bathroom remodel' },
    { src: workImage13, alt: 'Main Line placed in trench' },
    { src: workImage14, alt: 'Plumber Working On Main Line' },
    { src: workImage15, alt: 'Plumber In PPE Digging Hole' },
    { src: workImage16, alt: 'Buried Main Line Access' },
    { src: workImage17, alt: 'Main Line Concrete Access Point With Pipe' },
    { src: workImage18, alt: 'Mini Back Hoe on Jobsite' },
    { src: workImage19, alt: 'Pipe Being Measured For Proper Flow' },
    { src: workImage20, alt: 'Pipe Snake With Debris' },
    { src: workImage21, alt: 'Plumber Operating Back Hoe' },
    { src: workImage22, alt: 'Reinforced Trench' },
    { src: workImage23, alt: 'Plumber Testing Water Heater' },
    { src: workImage24, alt: 'Finished Buried Main Access' },
  ];

  return (
    <section className="gallery-section">
      <h2 className="gallery-title">Our Work</h2>
      <Slider {...settings}>
        {workImages.map((image, index) => (
          <div key={index} className="gallery-slide">
            <img src={image.src} alt={image.alt} className="gallery-image" />
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default Gallery;
